//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataApi from 'fm-data-api'

export default {
  name: 'Temoignage',
  components: {
  },
  data: function () {
    return {
      temoignages: [],
      temoignageToFocus: {}
    }
  },
  methods: {
    async getTemoignage () {
      const options = {
        apiUrl: this.$apiUrl,
        databaseName: this.$databaseName,
        token: await this.$getMainToken()
      }
      this.dataApi = new DataApi(options)
      return await this.dataApi.findRecords('W_Focus', { 'foc_RBQ__Rubrique::d_nomRubrique': 'Financement immobilier et professionnels', 'foc_rbq_SOC__Societe::d_nomSociete': 'CG Courtage', d_type: 'témoignage' }, '', '', '30')
        .then((focusList) => {
          const temoignages = []
          focusList.forEach(function (focus) {
            temoignages.push({
              titre: focus.fieldData.d_titre,
              texte: focus.fieldData.d_texte,
              theme: focus.fieldData.d_theme,
              image: focus.fieldData['foc_PHOP__PhotoPrincipale::d_image'],
              type: 'témoignage'
            })
          })
          return temoignages
        })
    },
    scrollTemoignage (direction) {
      this.sideScroll(
        document.getElementsByClassName('temoignage_container')[0], direction, 10, 300, 10)
    },
    sideScroll (element, direction, speed, distance, step) {
      let scrollAmount = 0
      var slideTimer = setInterval(
        function () {
          if (direction === 'left') {
            element.scrollLeft -= step
          } else {
            element.scrollLeft += step
          }
          scrollAmount += step
          if (scrollAmount >= distance) {
            window.clearInterval(slideTimer)
          }
        }, speed)
    },
    showTemoignageModal (temoignage) {
      this.temoignageToFocus = temoignage
      this.$bvModal.show('temoignageModal')
    }
  },
  async beforeMount () {
    await this.getTemoignage()
      .then(res => {
        this.temoignages = res
      })
  }
}
